import { Lightning } from "../model/model";

const getLightningDataOverview = (lightning: Lightning, minutesSinceLightning: number) => {
  let timeSince;

  if (minutesSinceLightning >= 120) {
    timeSince = `${Math.floor(minutesSinceLightning / 60)} h`;
  } else {
    timeSince = `${minutesSinceLightning} min`;
  }

  return (
    <>
      <h1 className="tooltip-h1">{timeSince}</h1>
      <p>{`${lightning.latitude}, ${lightning.longitude}`}</p>
      <p>{`${new Date(lightning.timestamp).toLocaleString()}`}</p>
      <p>{`${lightning.peak_current} kA, ${lightning.cloud_indicator ? "IC" : "CG"}, ${lightning.source}`}</p>
    </>
  );
};

const getLightningDataOverviewForIncident = (lightning: Lightning, secondsSince: number) => {
  return (
    <>
      <h1 className="tooltip-h1">{`${lightning.peak_current} kA, ${lightning.cloud_indicator ? "IC" : "CG"}`}</h1>
      <p>{`${new Date(lightning.timestamp).toLocaleString()}${secondsSince?", "+secondsSince+" s":""}`}</p>
      <p>{`${lightning.latitude}, ${lightning.longitude}`}</p>
      <p>{`${lightning.source}`}</p>
    </>
  );
}

const getLightningOpacity = (minutesSinceLightningStrike: number) => {
  minutesSinceLightningStrike = Math.abs(minutesSinceLightningStrike);
  if (minutesSinceLightningStrike > 120) {
    return 0.5;
  }
  if (minutesSinceLightningStrike > 60) {
    return 0.6;
  }
  if (minutesSinceLightningStrike > 10) {
    return 0.7;
  }
  if (minutesSinceLightningStrike > 5) {
    return 0.8;
  }
  if (minutesSinceLightningStrike > 2) {
    return 0.9;
  }

  return 1;
}


export { getLightningOpacity, getLightningDataOverview, getLightningDataOverviewForIncident };
