import { Control, ControlOptions } from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import userIcon from '../../assets/icons8-user-profile-64.png';
import './UserProfile.css'; // Import the CSS file
import { useAuth } from '../../App';
import { getUserInfo } from '../../api/apiService';

interface UserInfo {
  username: string;
  email: string;
  // Add any other user properties you want to display
}

const UserProfileControl = (props: ControlOptions) => {
  const map = useMap();
  const { logout } = useAuth();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const UserControl = Control.extend({
      onAdd: () => {
        const container = document.createElement('div');
        container.className = "user-profile-control";
        container.innerHTML = `
          <img src="${userIcon}" alt="User Profile" width="38" height="38" />
        `;
        container.onclick = () => {
          setIsOpen(!isOpen);
          if (!userInfo) {
            fetchUserInfo();
          }
        };
        return container;
      }
    });

    // Merge the default position with any props passed
    const controlOptions: ControlOptions = {
      position: 'bottomleft',
      ...props
    };

    const control = new UserControl(controlOptions);
    control.addTo(map);

    // Add this new code to handle clicks outside the popup
    const handleOutsideClick = (e: L.LeafletMouseEvent) => {
      const target = e.originalEvent.target as HTMLElement;
      if (!target.closest('.user-profile-control') && !target.closest('.user-profile-popup')) {
        setIsOpen(false);
      }
    };

    map.on('click', handleOutsideClick);

    return () => {
      control.remove();
      map.off('click', handleOutsideClick);
    };
  }, [map, props]);

  const fetchUserInfo = async () => {
    try {
      const info = await getUserInfo();
      setUserInfo(info);
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  };

  const handleSignOut = () => {
    logout();
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="user-profile-popup">
      {userInfo ? (
        <>
          <h3>{userInfo.username}</h3>
          <p><strong>{userInfo.email}</strong></p>
          {/* Add any other user info you want to display */}
          <button onClick={handleSignOut}>Sign Out</button>
        </>
      ) : (
        <p>Loading user info...</p>
      )}
    </div>
  );
};

export default UserProfileControl;