// IncidentsPage.tsx
import React, { useEffect, useState, useRef  } from 'react';
import Incident from '../../components/incident/Incidents';
import { Incident as IncidentType, IncidentData } from '../../model/model';
import { getIncidents, Filters as APIFilters } from "../../api/apiService";
import styles from './IncidentsPage.module.css';
import MapComponent from '../../components/maps/LeafletMapComponent';
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from '@material-ui/core/styles';
import FilterPanel from '../../components/FilterPanel/FilterPanel';
import { useSnackbar } from 'notistack';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IncidentAdd from '../../components/IncidentAdd/IncidentAdd';

// Update the Filters interface to extend the APIFilters
interface Filters extends APIFilters {}

const StyledPagination = withStyles({
    ul: {
      justifyContent: 'center',
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    root: {
        '& .MuiPaginationItem-page': {
          color: 'white',
        },
      }, 
  })(Pagination);

const defaultFilter: Filters = {};

const IncidentsPage: React.FC = () => {
  const [allIncidents, setAllIncidents] = useState<IncidentType[]>([]);
  const [incidents, setIncidents] = useState<IncidentType[]>([]);
  const [activeIncident, setActiveIncident] = useState<IncidentType | null>(null);
  const [activeIncidentData, setActiveIncidentData] = useState<IncidentData | null>(null);
  const [activeFilter, setActiveFilter] = useState<Filters>({
    regnum: '',
    callsign: '',
    hexident: ''
  });
  const [update, setUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 6
  
    // get total number of incidents
    useEffect(() => {
        const fetchTotalIncidents = async () => {
        const totalIncidents = await getIncidents(0, 10000, activeFilter);
        setTotalPages(Math.ceil(totalIncidents.length / pageSize));
        setAllIncidents(totalIncidents);
        };
        fetchTotalIncidents();
    }, [activeFilter, pageSize]);

    useEffect(() => {
        const skip = (page - 1) * pageSize;
        const fetchIncidents = async () => {
        const incidents = await getIncidents(skip, pageSize, activeFilter);
        const incidentsWithIndex = incidents.map((incident, index) => ({
            ...incident,
            index: index + skip, // add 1 because array index is 0-based
          }));        
        setIncidents(incidentsWithIndex);
                };
        fetchIncidents();
    }, [page, activeFilter, update]); 

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
      };

    useEffect(() => {
        const fetchIncidentData = async () => {
            // if (activeIncident) {
            //     try {
            //     const incidentData = await getIncidentData(activeIncident.id);
            //     setActiveIncidentData(incidentData);
            //     } catch (error) {
            //         enqueueSnackbar(`Process failed: ${(error as Error).message}`, { variant: 'error' });
            //         setActiveIncidentData(null);
            //     }
            // }
            }
        fetchIncidentData();
    }
    , [activeIncident]);

    const handleIncidentClick = (incident: IncidentType) => {
      setActiveIncident(incident);
    }; 

    const handleIncidentClickOnMap = (incident: IncidentType) => {
      // set filter to the incident
      setActiveFilter(prevState => ({ ...prevState, id: incident.id }));
      setActiveIncident(incident);
    }; 

    const handleUpdate = () => {
        setUpdate(prevState => !prevState);
        setActiveIncident(activeIncident)
      };    

    const handleFilterChange = (filters: Filters) => {
            setActiveFilter(filters);
            setPage(1);
        };        

    const handleFabClick = () => {
      //setAddDialogOpen(true);
    };

    const handleSubmitIncident = async (incident: IncidentType) => {
    //   console.log("Saving incident:",incident);
    //   try {
    //     let result = await addIncident(incident);
    //     enqueueSnackbar(`Incident added successfully`, { variant: 'success' });
    //   }
    //   catch (error) {
    //     enqueueSnackbar(`Failed to add incident: ${(error as Error).message}`, { variant: 'error' });
    //   }
    //   finally {
    //     setUpdate(prevState => !prevState);
    // }
    }

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setActiveIncident(null);
          setActiveIncidentData(null);
          setActiveFilter(defaultFilter);
          setPage(1);
        }
    
      };
    
      window.addEventListener('keydown', handleKeyDown);
    
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

  return (
    <div className={styles.page}>

        <div className={styles.incidents}>

          <div className={styles.incidentItemsWrapper} >
            <div className={styles.filters}>
                <FilterPanel onFilterChange={handleFilterChange} />
            </div>
            <ul className={styles.list}>
                {incidents.map((incident) => (
                    <Incident key={incident.id} 
                    incident={incident} 
                    incidentData={activeIncidentData} 
                    index={incident.index} 
                    onClick={handleIncidentClick} 
                    isActive={incident === activeIncident}
                    onProcess={handleUpdate}
                    />
                ))}
            </ul>

            <div className={styles.totalIncidents}>
            Total {allIncidents.length}
            </div>   

          </div>

          <StyledPagination color="primary" count={totalPages} page={page} onChange={handlePageChange} />

          <div className={styles.addButton}>
              <Fab color="primary" aria-label="add" onClick={handleFabClick}>
                  <AddIcon />
              </Fab>
          
          </div>        
        </div>

          <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} maxWidth="xl">
            <DialogTitle>Add New Incident</DialogTitle>
            <DialogContent>
              <IncidentAdd incident={
                {
                  id: '',
                  hash: '',
                  hexident: '',
                  callsign: '',
                  regnum: '',
                  damage_score: 0,
                  reported_damage: '',
                  event_type: '',
                  AOG: 0,
                  confirmed: false,
                  miss: false,
                  comment: '',
                  flight_phase: '',
                  detected_by: '',
                  distance: 0,
                  peak_current: 0,
                  AI: 0,
                  nac_p: 0,
                  latitude: 0,
                  longitude: 0,
                  altitude: 0,
                  timestamp: '',
                  notified: false,
                  created_at: '',
                  updated_at: '',
                  index: 0
                }
              } 
                            onSubmit={(incident) => {
                                  handleSubmitIncident(incident);
                            }}
              />
            </DialogContent>
          </Dialog>      

        <div className={styles.map}>
            <MapComponent 
            key={activeIncident?.id}
            incidents={allIncidents}
            activeIncident={activeIncident} 
            activeIncidentData={activeIncidentData}
            onIncidentClick={handleIncidentClickOnMap}
             />
        </div>
    </div>

  );
};

export default IncidentsPage;