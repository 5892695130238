import { Control, ControlOptions } from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import incidentIcon from '../../assets/icons8-lightning-48.png';
import './IncidentControl.css';

const IncidentControl = (props: ControlOptions) => {
  const map = useMap();
  const navigate = useNavigate();

  useEffect(() => {
    const IncidentControl = Control.extend({
      onAdd: () => {
        const container = document.createElement('div');
        container.className = "incident-control";
        container.innerHTML = `
          <img src="${incidentIcon}" alt="Report Incident" width="38" height="38" />
        `;
        
        container.onclick = () => {
          navigate('/incidents');
        };

        return container;
      }
    });

    const controlOptions: ControlOptions = {
      position: 'bottomleft',
      ...props
    };

    const control = new IncidentControl(controlOptions);
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map, props, navigate]);

  return null;
};

export default IncidentControl;