import { Airplane, ApiResponse } from "../model/model";
const threddsMetUrl = "https://thredds.met.no/thredds";
const airplanesApiUrl = "https://api.airplanes.live/v2";

const getNordicReflectivityId = async (): Promise<string> => {
  const url = `${threddsMetUrl}/catalog/remotesensing/reflectivity-nordic/latest/catalog.xml`;
  return await fetch(url)
    .then(response => response.text())
    .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
    .then(data => {
        return data.children[0].children[1].children[1].attributes[1].value;
      }
    );
};


export { threddsMetUrl, getNordicReflectivityId }
