import { Flight, Lightning, Incident, IncidentData, Airplane, Damage, Attachment } from "../model/model";
import { deleteOperation, get, post } from "./http";
import Cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8080/api";
const FlightsLimit = process.env.REACT_APP_FLIGHTS_LIMIT || 2000;
const SfericsLimit = process.env.REACT_APP_SFERICS_LIMIT || 1000;

const getAuthHeaders = (): HeadersInit => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

async function getFlights(): Promise<Flight[]> {
  try {
    const response = await fetch(`${baseUrl}/Flights/db/?limit=${FlightsLimit}`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching flights:", error);
    throw error;
  }
};

const getFlightHistory = async (callSign: string): Promise<Flight[] | undefined> => {
  try {
    const response = await fetch(`${baseUrl}/Flights/callsign/${callSign}`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching flight history:", error);
    throw error;
  }
};

const getMinimalLightnings = async (skip: number = 0): Promise<Lightning[]> => {
  let url = `${baseUrl}/Lightnings/minimal?limit=${SfericsLimit}&skip=${skip}`;
  
  const LightningHistoryDays = Cookies.get('lightningHistoryDays') || 2;
  const fromDate = new Date(new Date().getTime() - Number(LightningHistoryDays) * 24 * 60 * 60 * 1000);

  fromDate.setSeconds(0, 0);
  fromDate.setMinutes(fromDate.getMinutes() + 1);

  url += `&from_date=${fromDate.toISOString()}`;

  try {
    const response = await fetch(url, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching minimal lightnings:", error);
    throw error;
  }
};

export interface Filters {
  regnum?: string;
  callsign?: string;
  hexident?: string;
  [key: string]: string | undefined;
}

const getIncidents = async (skip: number, limit: number, filters: Filters): Promise<Incident[]> => {
  const queryString = Object.keys(filters)
    .filter(key => filters[key] !== undefined && filters[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key] || '')}`)
    .join('&');  
  try {
    const response = await fetch(`${baseUrl}/Incident?skip=${skip}&limit=${limit}&${queryString}`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching incidents:", error);
    throw error;
  }
}

const getIncidentData = async (id: string): Promise<IncidentData> => {
  const response = await fetch(`${baseUrl}/Incident/data?incident_id=${id}`, {  
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch incident data for incident with id ${id}`);
  }
  const result = await response.json();
  return result || {};
}

const updateIncident = async (incident: Incident): Promise<void> => { 
  const response = await fetch(`${baseUrl}/Incident/update?incident_id=${incident.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(incident),
  });
  if (!response || !response.ok) {
    throw new Error(`Failed to update incident with id ${incident.id}`);
  }
}

const removeIncident = async (id: string): Promise<void> => {
  await deleteOperation(`${baseUrl}/Incident/delete?incident_id=${id}`, getAuthHeaders())
}

const submitAcRegistration = async (acInfo: Airplane): Promise<void> => {
  try {
    const response = await fetch(`${baseUrl}/Aircraft/add-registration`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(acInfo),
    });
    if (!response.ok) {
      throw new Error(`Failed to submit aircraft registration: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error submitting aircraft registration:", error);
    throw error;
  }
}

export interface SignInResponse {
  access_token: string;
}

const signIn = async (username: string, password: string): Promise<SignInResponse> => {
  try {
    const response = await fetch(`${baseUrl}/users/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        username: username,
        password: password,
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'An error occurred during sign in');
    }

    return response.json();
  } catch (error) {
    console.error("Error during sign in:", error);
    throw error;
  }
};

export const getUserInfo = async (): Promise<{ username: string; email: string }> => {
  try {
    const response = await fetch(`${baseUrl}/users/me`, {
      headers: getAuthHeaders()
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};

const getUserProfile = async (): Promise<{ username: string; email: string }> => {
  try {
    const response = await fetch(`${baseUrl}/users/me`, {
      headers: getAuthHeaders()
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user profile');
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

const requestPasswordReset = async (email: string): Promise<void> => {
  try {
    const response = await fetch(`${baseUrl}/users/request-password-reset`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({ email: email }),
    });
    if (!response.ok) {
      throw new Error('Failed to request password reset');
    }
  } catch (error) {
    console.error("Error requesting password reset:", error);
    throw error;
  }
};

export const resetPassword = async (email: string, token: string, newPassword: string) => {
  const response = await fetch(`${baseUrl}/users/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, token, new_password: newPassword }),
  });

  if (!response.ok) {
    throw new Error('Failed to reset password');
  }

  return response.json();
};

const signOut = async (): Promise<void> => {
  try {
    // Clear the token from localStorage
    localStorage.removeItem('token');

    // Optionally, you can also make an API call to invalidate the token on the server
    // const response = await fetch(`${baseUrl}/users/logout`, {
    //   method: 'POST',
    //   headers: getAuthHeaders(),
    // });
    // if (!response.ok) {
    //   throw new Error('Failed to logout on the server');
    // }

    // Clear any other user-related data from localStorage or cookies if needed
    // For example:
    // localStorage.removeItem('user');
    // Cookies.remove('someUserRelatedCookie');

  } catch (error) {
    console.error("Error during sign out:", error);
    throw error;
  }
};

const importTrack = (id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        try {
          const response = await fetch(`${baseUrl}/Incident/import-track?incident_id=${id}`, {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
          });
          if (!response.ok) {
            throw new Error(`Failed to import track for incident with id ${id}`);
          }
          const result = await response.json();
          console.log('Import successful:', result);
          resolve();
        } catch (error) {
          console.error('Error importing track:', error);
          reject(error);
        }
      } else {
        reject(new Error('No file selected'));
      }
    };
    input.click();
  });
};

const getDamage = async (incident_id: string): Promise<Damage> => {
  const response = await fetch(`${baseUrl}/damage/${incident_id}`, {
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch damage for incident with id ${incident_id}`);
  }
  return response.json();
}

const getIncidentAttachments = async (incident_id: string): Promise<Attachment[]> => {
  const response = await fetch(`${baseUrl}/Attachment/${incident_id}`, {
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch attachments for incident with id ${incident_id}`);
  }
  return response.json();
}

const addAttachment = async (incident_id: string, attachment: Attachment, file: File): Promise<Attachment> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('attachment', JSON.stringify(attachment));

  const response = await fetch(`${baseUrl}/Attachment/attach-file?incident_id=${incident_id}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`Failed to add attachment for incident with id ${incident_id}`);
  }

  return response.json();
}

const getAttachment = async (attachment_id: string): Promise<Blob> => {
  try {
    const response = await fetch(`${baseUrl}/Attachment/download/${attachment_id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    });
    if (!response.ok) {
      const errorText = await response.text();
      console.error(`Server responded with ${response.status}: ${errorText}`);
      throw new Error(`Failed to fetch attachment with id ${attachment_id}. Status: ${response.status}`);
    }
    return await response.blob();
  } catch (error) {
    console.error('Error in getAttachment:', error);
    throw error;
  }
};

const deleteAttachment = async (attachment_id: string): Promise<void> => {
  await deleteOperation(`${baseUrl}/Attachment/${attachment_id}`, getAuthHeaders());
};

export {
  getFlights,
  getFlightHistory,
  getMinimalLightnings,
  getIncidents,
  getIncidentData,
  updateIncident,
  removeIncident,
  submitAcRegistration,
  signIn,
  getUserProfile,
  requestPasswordReset,
  signOut,
  importTrack,
  getDamage,
  getAttachment,
  getIncidentAttachments,
  deleteAttachment,
  addAttachment,
};
