import L from 'leaflet';
import {
  CircleMarker,
  LayerGroup,
  Marker,
  Polyline,
  Popup,
  Tooltip,
  useMapEvents
} from "react-leaflet";
import { Flight } from "../model/model";
import { getMinutesSince } from "../utils/DateUtil";
import { getFlightPosition } from "../utils/FlightUtil";
import { activeFlightIcon, estimateFlightIcon, activeFlightShadowIcon, flightIcon, helicopterIcon, activeHelicopterIcon, flightStartIcon, helicopterShadowIcon } from "./Icons";
import { VictoryLine, VictoryChart, VictoryTheme } from 'victory';
import './FlightMarker.module.css';

const RemoveActiveCallSign = ({ setActiveCallSign, setFlightTrails }: any) => {
  useMapEvents({
    async click(_) {
      setActiveCallSign([]);
      setFlightTrails([]);
    }
  });
  return null;
}

const RemoveEstimatedPositions = ({ setEstimatedPositions }: any) => {
  useMapEvents({
    async click(_) {
      setEstimatedPositions([]);
    }
  });
  return null;
}

const drawCircleMarker = (flight: Flight, i: number) => {
  const minutesSincePosition = getMinutesSince(flight.position_datetime);
  return (
    <CircleMarker
      key={`circle-${i}`}
      radius={2}
      weight={2}
      color={"#16425B"}
      center={getFlightPosition(flight)}
      fill={false}
      interactive={true}
    >
      <Tooltip sticky>
        <h1 className="tooltip-h1">{`${minutesSincePosition} min`}</h1>
        <p>{`${new Date(flight.position_datetime).toLocaleString()}`}</p>
        <p>{`Ground speed: ${flight.ground_speed} kts`}</p>
        <p>{`${flight.latitude},${flight.longitude},${flight.altitude}`}</p>
        <p>{`${flight.source}`}</p>
      </Tooltip>
    </CircleMarker>
  );
}

const drawCircleMarkerForIncident = (flight: Flight) => {
  return (
    <CircleMarker
      key={`circle-${flight.position_datetime}`}
      radius={2}
      weight={2}
      color={"#16425B"}
      center={getFlightPosition(flight)}
      fill={false}
      interactive={true}
    >
      <Tooltip sticky>
        <h1 className="tooltip-h1">{`${flight.altitude} feet`}</h1>
        <p>{`${new Date(flight.position_datetime).toLocaleString()}`}</p>
        <p>{`${flight.latitude.toFixed(4)}, ${flight.longitude.toFixed(4)}`}</p>
        <p>{`Ground speed: ${flight.ground_speed} kts`}</p>
        <p>{`Track: ${flight.track}°`}</p>
        <p>{`${flight.source}`}</p>
      </Tooltip>
    </CircleMarker>
  );
}

const drawEstimatedPosition = (flight: Flight, setActiveCallSign: any) => {
  const clickEvent = (event: L.LeafletMouseEvent) => {
    setActiveCallSign([flight.callsign]);
  }
  return (
    <Marker
      key={`flight-${flight.hexident}-${flight.position_datetime}-${flight.latitude}`}
      icon={estimateFlightIcon}
      position={getFlightPosition(flight)}
      zIndexOffset={1000}
      rotationAngle={flight.track}
      rotationOrigin="center"
      keyboard={false}
      eventHandlers={{
        click: clickEvent
      }
      }
    >
      <Tooltip sticky>
        <h1 className="tooltip-h1">{flight.callsign}</h1>
        <p>{`${new Date(flight.position_datetime).toLocaleString()}`}</p>
        <p>{`${flight.latitude},${flight.longitude},${flight.altitude}`}</p>
        <p>{`Ground speed: ${flight.ground_speed} kts`}</p>
      </Tooltip>
    </Marker>
  );
}

const drawIncidentPosition = (flight: Flight, track: Flight[]) => {
  const altitude_data = track.map((f: Flight) => {
    return { x: new Date(f.position_datetime), y: f.altitude };
  }
  );
  const incidentTimestamp = new Date(flight.position_datetime);

  return (
    <Marker
      key={`flight-${flight.hexident}-${flight.position_datetime}-${flight.latitude}`}
      icon={activeFlightIcon}
      position={getFlightPosition(flight)}
      zIndexOffset={1000}
      rotationAngle={flight.track}
      rotationOrigin="center"
      keyboard={false}
    >
      <Tooltip sticky>
        <h1 className="tooltip-h1">{flight.regnum? flight.regnum:""} {flight.callsign}</h1>
        <p>{`${new Date(flight.position_datetime).toLocaleString()}`}</p>
        <p>{`${flight.latitude.toFixed(4)},${flight.longitude.toFixed(4)},${flight.altitude}`}</p>
        <p>{`Ground speed: ${flight.ground_speed} kts`}</p>
        <p>{`Track: ${flight.track}°`}</p>
      </Tooltip>
      <Popup className="wide-popup">
        <div>
          <h1>{flight.regnum? flight.regnum:""} {flight.callsign}</h1>
          <p>{`${new Date(flight.position_datetime).toLocaleString()}`}</p>
          <p>{`${flight.latitude},${flight.longitude},${flight.altitude}`}</p>
          <VictoryChart theme={VictoryTheme.material} >
            <VictoryLine data={altitude_data} />
            
            <VictoryLine
              data={[
                { x: incidentTimestamp, y: 0 },
                { x: incidentTimestamp, y: Math.max(...altitude_data.map(d => d.y)) },
              ]}
              style={{ data: { stroke: "red", strokeWidth: 2 } }}
            />            
          </VictoryChart>        
        </div>
      </Popup>
    </Marker>
  );
}

const drawIncidentPath = (track: Flight[], color: string) => {
  const drawnCircleMarkers: any = [];
  if (track && track.map) {  
  const trailPositions = track.map((f: Flight) => {
    drawnCircleMarkers.push(drawCircleMarkerForIncident(f));
    return getFlightPosition(f);
  });
  const pathLine = () => {
    return (
      <div key={`flight-${track[0].callsign}`}>
        <Polyline
          positions={trailPositions}
          color={color}
          interactive={false}
          weight={6}
        />
        {drawnCircleMarkers}
      </div>
    );
  }
  return pathLine();
  }

}

const drawFlightPath = (flightTrail: Flight[], flight: Flight, idx: number) => {
  const drawnCircleMarkers: any = [];

  const trailPositions = flightTrail.map((f: Flight, idx) => {
    idx < flightTrail.length - 1 && drawnCircleMarkers.push(drawCircleMarker(f, idx));
    return getFlightPosition(f);
  });
  // save track to clipboard
  // navigator.clipboard.writeText(objectToCsv(flightTrail));

  const pathLine = () => {
    return (
      <div key={`flight-${flight.callsign}-index-${idx}`}>
        <Polyline
          positions={trailPositions}
          color={"#628395"}
          interactive={false}
          weight={6}
        />
        {drawnCircleMarkers}
        <Marker
          key={`start-${flight.hexident}-${flight.position_datetime}-${flight.latitude}`}
          position={getFlightPosition(flightTrail[flightTrail.length - 1])}
          icon={flightStartIcon}
          interactive={false}
          keyboard={false}
        />
      </div>
    );
  }
  return pathLine();
}

const helicopterAcTypes = ["S92", "AS50", "EC45", "EC35", "B427", "A139", "EH10"];

const FlightMarker = (
  flight: Flight,
  flightTrails: Flight[][],
  setClickedCallSign: any,
  setActiveCallSign: any,
  activeCallSigns: string[],
  estimatedPositions: Flight[]) => {

  const clickEvent = (event: L.LeafletMouseEvent) => {
    setClickedCallSign(flight.callsign);
    if (event.originalEvent.shiftKey) {
      const callSignIndex = activeCallSigns.indexOf(flight.callsign);
      if (callSignIndex >= 0) {
        const newCallSigns = [...activeCallSigns];
        newCallSigns.splice(callSignIndex, 1);
        setActiveCallSign(newCallSigns);
      } else {
        setActiveCallSign([...activeCallSigns, flight.callsign]);
      }
    } else {
      setActiveCallSign([flight.callsign]);
    }
  }
  const icon = helicopterAcTypes.includes(flight.ac_type ?? "") ? helicopterIcon : flightIcon;
  const activeIcon = helicopterAcTypes.includes(flight.ac_type ?? "") ? activeHelicopterIcon : activeFlightIcon;
  const shadowIcon = helicopterAcTypes.includes(flight.ac_type ?? "") ? helicopterShadowIcon : activeFlightShadowIcon;

  return (
    <Marker
      key={`flight-${flight.hexident}-${flight.position_datetime}-${flight.latitude}`}
      icon={activeCallSigns.includes(flight.callsign) ? activeIcon : icon}
      position={getFlightPosition(flight)}
      zIndexOffset={1000}
      rotationAngle={flight.track}
      rotationOrigin="center"
      keyboard={false}
      eventHandlers={{
        click: clickEvent
      }}
    >
      {/* small callsign marker */}
      {!activeCallSigns.includes(flight.callsign) && (
        <LayerGroup>
          <Marker
            key={`flight-${flight.hexident}-${flight.position_datetime}-${flight.latitude}`}
            icon={L.divIcon({
              html: flight.callsign,
              className: "flight-callsign"
            })}
            position={getFlightPosition(flight)}
            zIndexOffset={5000}
            keyboard={false}
          />
        </LayerGroup>
      )}

      {estimatedPositions?.map((estimatedPosition: Flight) =>
        drawEstimatedPosition(estimatedPosition, setActiveCallSign)
      )}

      <Tooltip sticky>
        <h1 className="tooltip-h1">{flight.regnum? flight.regnum:flight.callsign}</h1>
        <p>{`${Math.floor((new Date().getTime() - Date.parse(flight.position_datetime + 'Z')) / 1000)} sec, ${flight.ground_speed} kts, ${flight.altitude} ft`}</p>
        <p>{`${flight.hexident} ${flight.ac_type? flight.ac_type:""}`}</p>
      </Tooltip>
      {flightTrails && activeCallSigns.includes(flight.callsign) && (
        <LayerGroup>
          <Marker
            key={`call-sign-${flight.hexident}-${flight.position_datetime}-${flight.latitude}`}
            position={getFlightPosition(flight)}
            icon={L.divIcon({
              html: flight.regnum? flight.regnum:flight.callsign,
              className: "flight-popup"
            })}
            opacity={0.75}
            interactive={false}
            keyboard={false} />

          <Marker
            key={`shadow-${flight.hexident}-${flight.position_datetime}-${flight.latitude}`}
            position={getFlightPosition(flight)}
            icon={shadowIcon}
            zIndexOffset={-10000}
            rotationAngle={flight.track}
            rotationOrigin="center"
            opacity={1}
            interactive={false}
            keyboard={false}
          />

          {flightTrails?.map((flightTrail: Flight[], idx: number) =>
            drawFlightPath(flightTrail, flight, idx)
          )}

        </LayerGroup>
      )}
    </Marker>
  );
}

export { 
  FlightMarker, 
  RemoveActiveCallSign, 
  RemoveEstimatedPositions,
  drawFlightPath,
  drawIncidentPath,
  drawEstimatedPosition,
  drawIncidentPosition
};
