import L from "leaflet";
import warningIcon from '../../assets/icons8-exclamation-24.png';
import './IncidentWarningSign.css';

export const incidentIcon = L.divIcon({
  html: `<div class="incident-icon-wrapper"><img src="${warningIcon}" class="incident-icon"></div>`,
  className: 'incident-icon-container',
  iconSize: [24, 24],
  iconAnchor: [12, 12],
  popupAnchor: [0, -12],
});


