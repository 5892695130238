import data from '../../assets/map.json';
import * as turf from '@turf/turf';

export const reldasFeature = data.features.find(feature => feature.properties);
export const reldasPolygon = reldasFeature && turf.polygon(reldasFeature.geometry.coordinates);
export const worldPolygon = turf.polygon([[
  [-180, -90],
  [-180, 90],
  [180, 90],
  [180, -90],
  [-180, -90]
]]);

export const maskPolygon = reldasPolygon && turf.difference(worldPolygon, reldasPolygon);
export const outStyle = { fillColor: 'gray', fillOpacity: .5, weight: 1, color: 'gray' };