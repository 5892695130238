// IncidentEdit.tsx
import React, { useState } from 'react';
import { Incident } from '../../model/model';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './IncidentEdit.module.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


interface IncidentEditProps {
    incident: Incident;
    onSubmit: (incident: Incident) => void;
    onRemove: (incident: Incident) => void;
    onDelete: (incident: Incident) => void;
}

const IncidentEdit: React.FC<IncidentEditProps> = ({ incident, onSubmit, onRemove, onDelete }) => {
    const [editedIncident, setEditedIncident] = useState(incident);

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => {
        const { name } = event.target;
      
        setEditedIncident(prevState => ({
          ...prevState,
          [name]: checked,
        }));
      };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
      
        setEditedIncident(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(editedIncident);
    };

    const handleCancel = () => {
        setEditedIncident(incident);
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
          handleSubmit(event);
        }
      };    

    const handleRemove = () => {
        onRemove(incident);
    }

    const handleDelete = () => {
        onDelete(incident);
    }

    return (
        <form onSubmit={handleSubmit} className={styles.form} onKeyDown={handleKeyDown}>
            <div className={styles.row}>
            <TextField className={styles.textField} name="id" label="ID" value={editedIncident.id} disabled />
            <TextField className={styles.textField} name="Timestamp" label="UTC" value={editedIncident.timestamp} disabled />
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                <TextField name="hexident" label="Hexident" value={editedIncident.hexident} onChange={handleChange} />
                <TextField name="callsign" label="Callsign" value={editedIncident.callsign} onChange={handleChange} />
                </div>
                <div className={styles.column}>

                <TextField name="regnum" label="Registration" value={editedIncident.regnum} onChange={handleChange} />
                <TextField name="flight_phase" label="Flight phase" value={editedIncident.flight_phase} onChange={handleChange} />
                </div>
                <div className={styles.column}>
                <TextField name="damage_score" label="Damage pred" value={editedIncident.damage_score} onChange={handleChange} />
                <TextField name="reported_damage" label="Damage reported" value={editedIncident.reported_damage} onChange={handleChange} />
                </div>
                <div className={styles.column}>
                <TextField name="event_type" label="Event type" value={editedIncident.event_type} onChange={handleChange} />
                <TextField name="AOG" label="AOG" value={editedIncident.AOG} onChange={handleChange} />
                </div>
            </div>
            {editedIncident.regnum && (
  <Button 
    size="small" 
    variant="outlined" 
    onClick={() => window.open(`https://www.flightradar24.com/data/aircraft/${editedIncident.regnum.toLowerCase()}#`, '_blank', 'noopener noreferrer')}
  >
    View on Flightradar24
  </Button>
)}
        <div className={styles.row}>
            <FormControlLabel
                            control={
                            <Checkbox
                            checked={editedIncident.confirmed || false}
                            onChange={handleCheckBoxChange}
                            name="confirmed"
                            color="primary"
                            />
                            }
                            label="Confirmed"
                        />
            <FormControlLabel
                            control={
                            <Checkbox
                            checked={editedIncident.miss || false}
                            onChange={handleCheckBoxChange}
                            name="miss"
                            color="primary"
                            />
                            }
                            label="Miss"
                        />
        </div>

            <TextField
                name="comment"
                label="Comment"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth
                value={editedIncident.comment || ''}
                onChange={handleChange}
            />

            <div className={styles.buttonContainer}>
                <div className={styles.buttonSubGroup}>
                <Button onClick={handleRemove}>Remove</Button>
                <Button onClick={handleDelete}>Delete</Button>
                </div>
                <div className={styles.buttonSubGroup}>
                    <Button onClick={handleCancel}>Reset</Button>
                    <Button type="submit">Submit</Button>
                    </div>
            </div>

        </form>
    );
};

export default IncidentEdit;