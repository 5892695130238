import { getFlightHistory } from "../api/apiService";
import { Flight } from "../model/model";


const displayFlightTrail = async (callSign: string, setFlightTrails: any, flightTrails: Flight[][], setActiveCallSign: any, activeCallSigns: string[]) => {
  if (!callSign) {
    return;
  }

  if (!activeCallSigns.includes(callSign)) {
    const newFlightTrails = flightTrails.filter(flightTrail => flightTrail[0].callsign !== callSign);
    setFlightTrails(newFlightTrails);
    return;
  }

  const flightTrail = await getFlightHistory(callSign);
  if (!flightTrail) {
    setActiveCallSign([]);
    return;
  }


  if (flightTrails?.length > activeCallSigns?.length || activeCallSigns?.length === 1) {
    setFlightTrails([flightTrail]);
    return;
  }

  const trailsIndex = flightTrails.findIndex(flights => flights[0].callsign === callSign);

  if (trailsIndex !== -1) {
    const flightTrailsTemp = flightTrails;
    flightTrailsTemp[trailsIndex] = flightTrail;
    setFlightTrails(flightTrailsTemp);
    return;
  }

  setFlightTrails([...flightTrails, flightTrail]);
}

const displayAllFlightTrails = async (setFlightTrails: any, flightTrails: Flight[][], setActiveCallSign: any, activeCallSigns: string[]) => {
  for (let callSign of activeCallSigns) {
    await displayFlightTrail(callSign, setFlightTrails, flightTrails, setActiveCallSign, activeCallSigns);
  }
}

const getFlightPosition = (flight: Flight) => {
  return { lat: flight.latitude, lng: flight.longitude };
}


export { displayAllFlightTrails, displayFlightTrail, getFlightPosition };
