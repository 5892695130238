const getMinutesSince = (time: Date) => {
  const then = new Date(time).getTime();
  const now = new Date().getTime();
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const minutesSince = (now - then + timezoneOffset) / 60000;

  return Math.abs(Math.floor(minutesSince));
}


export { getMinutesSince };
