// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* IncidentEdit.module.css */\n.IncidentEdit_form__P3dmx {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    gap: 40px;\n    width: 600px;\n}\n\n.IncidentEdit_textField__\\+v3se {\n    width: 100%;\n}\n\n.IncidentEdit_column__QpeSr {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;}\n\n.IncidentEdit_row__z2z8y {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n  }\n \n  .IncidentEdit_buttonContainer__Yujdb {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .IncidentEdit_buttonSubGroup__z6py4 {\n    display: flex;\n    gap: 10px;\n  }\n\n\n", "",{"version":3,"sources":["webpack://./src/components/IncidentEdit/IncidentEdit.module.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS,CAAC;;AAEd;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,SAAS;EACX","sourcesContent":["/* IncidentEdit.module.css */\n.form {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    gap: 40px;\n    width: 600px;\n}\n\n.textField {\n    width: 100%;\n}\n\n.column {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;}\n\n.row {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n  }\n \n  .buttonContainer {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .buttonSubGroup {\n    display: flex;\n    gap: 10px;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "IncidentEdit_form__P3dmx",
	"textField": "IncidentEdit_textField__+v3se",
	"column": "IncidentEdit_column__QpeSr",
	"row": "IncidentEdit_row__z2z8y",
	"buttonContainer": "IncidentEdit_buttonContainer__Yujdb",
	"buttonSubGroup": "IncidentEdit_buttonSubGroup__z6py4"
};
export default ___CSS_LOADER_EXPORT___;
