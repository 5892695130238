// IncidentEdit.tsx
import React, { useState, useEffect } from 'react';
import { Attachment, Damage, Incident, IncidentData } from '../../model/model';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles from './IncidentEdit.module.css';
import { getAttachment, deleteAttachment, addAttachment } from '../../api/apiService'; // Assuming this function exists
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface IncidentEditProps {
    incident: Incident;
    incidentData: IncidentData;
    incidentDamageData: Damage | null;
    incidentAttachments: Attachment[];
    onSubmit: (incident: Incident, incidentData: IncidentData) => void;
    onRemoveIncident: (incident: Incident) => void;
    onDeleteIncident: (incident: Incident) => void;
    onCancel: () => void; 
    onAttachmentDeleted: () => void; 
    onAttachmentUploaded: (newAttachment: Attachment) => void; 
}

const IncidentEdit: React.FC<IncidentEditProps> = ({ incident, incidentData, incidentDamageData, incidentAttachments, onSubmit, onRemoveIncident, onDeleteIncident, onCancel, onAttachmentDeleted, onAttachmentUploaded }) => {
    const [editedIncident, setEditedIncident] = useState(incident);
    const [editedIncidentData, setEditedIncidentData] = useState(incidentData);
    const [deleteConfirmation, setDeleteConfirmation] = useState<{ open: boolean; attachmentId: string | null }>({ open: false, attachmentId: null });
    
    useEffect(() => {
        setEditedIncident(incident);
        setEditedIncidentData(incidentData);
    }, [incident, incidentData]);

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => {
        const { name } = event.target;
      
        setEditedIncident(prevState => ({
          ...prevState,
          [name]: checked,
        }));
      };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
      
        setEditedIncident(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(editedIncident, editedIncidentData);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
          handleSubmit(event);
        }
      };    

    const handleRemove = () => {
        onRemoveIncident(incident);
    }

    const handleDelete = () => {
        onDeleteIncident(incident);
    }

    const handleDownload = async (attachment: Attachment) => {
        try {
            const blob = await getAttachment(attachment.id);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = attachment.filename || 'download';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    const handleDeleteConfirmation = (attachmentId: string) => {
        setDeleteConfirmation({ open: true, attachmentId });
    };

    const handleConfirmDelete = async () => {
        if (deleteConfirmation.attachmentId) {
            await handleDeleteAttachment(deleteConfirmation.attachmentId);
        }
        setDeleteConfirmation({ open: false, attachmentId: null });
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation({ open: false, attachmentId: null });
    };

    const handleDeleteAttachment = async (attachmentId: string) => {
        try {
            await deleteAttachment(attachmentId);
            const updatedIncidentData = {
                ...editedIncidentData,
                attachment_data: editedIncidentData.attachment_data?.filter(att => att.id !== attachmentId) ?? []
            };
            setEditedIncidentData(updatedIncidentData);
            onSubmit(editedIncident, updatedIncidentData);
            onAttachmentDeleted(); 
        } catch (error) {
            console.error('Failed to delete attachment:', error);
        }
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        try {
            const formData = new FormData();
            formData.append('file', file);

            const newAttachment: Attachment = {
                id: '', // This will be assigned by the server
                filename: file.name,
                incident_id: incident.id,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
            };

            const uploadedAttachment = await addAttachment(incident.id, newAttachment, file);
            onAttachmentUploaded(uploadedAttachment);
        } catch (error) {
            console.error('Failed to upload attachment:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form} onKeyDown={handleKeyDown}>
            <div className={styles.row}>
            <TextField className={styles.textField} name="id" label="ID" value={editedIncident.id} disabled />
            <TextField className={styles.textField} name="Timestamp" label="UTC" value={editedIncident.timestamp} disabled />
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                <TextField name="hexident" label="Hexident" value={editedIncident.hexident} onChange={handleChange} />
                <TextField name="callsign" label="Callsign" value={editedIncident.callsign} onChange={handleChange} />
                </div>
                <div className={styles.column}>

                <TextField name="regnum" label="Registration" value={editedIncident.regnum} onChange={handleChange} />
                <TextField name="flight_phase" label="Flight phase" value={editedIncident.flight_phase} onChange={handleChange} />
                </div>
                <div className={styles.column}>
                <TextField name="damage_score" label="Damage pred" value={editedIncident.damage_score} onChange={handleChange} />
                <TextField name="reported_damage" label="Damage reported" value={editedIncident.reported_damage} onChange={handleChange} />
                </div>
                <div className={styles.column}>
                <TextField name="event_type" label="Event type" value={editedIncident.event_type} onChange={handleChange} />
                <TextField name="AOG" label="AOG" value={editedIncident.AOG} onChange={handleChange} />
                </div>
            </div>
            {editedIncident.regnum && (
                <Button 
                    size="small" 
                    variant="outlined" 
                    onClick={() => window.open(`https://www.flightradar24.com/data/aircraft/${editedIncident.regnum.toLowerCase()}#`, '_blank', 'noopener noreferrer')}
                >
                    View on Flightradar24
                </Button>
            )}

            <TextField
                name="comment"
                label="Comment"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth
                value={editedIncident.comment || ''}
                onChange={handleChange}
            />

            <div className={styles.attachmentsSection}>
                <h3>Attachments</h3>
                {incidentAttachments && incidentAttachments.length > 0 ? (
                    <ul className={styles.attachmentList}>
                        {incidentAttachments.map((attachment: Attachment, index: number) => (
                            <li key={attachment.id} className={styles.attachmentItem}>
                                <Button
                                    onClick={() => handleDownload(attachment)}
                                    variant="text"
                                    color="primary"
                                >
                                    {attachment.filename || `Attachment ${index + 1}`}
                                </Button>
                                <IconButton
                                    onClick={() => handleDeleteConfirmation(attachment.id)}
                                    color="secondary"
                                    aria-label="delete attachment"
                                    className={styles.deleteIcon}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No attachments available</p>
                )}
                <input
                    accept="*/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleFileUpload}
                />
                <label htmlFor="raised-button-file">
                    <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload
                    </Button>
                </label>
            </div>

            <div className={styles.buttonContainer}>
                <div className={styles.buttonSubGroup}>
                <Button onClick={handleRemove}>Remove</Button>
                {/* <Button onClick={handleDelete}>Delete</Button> */}
                </div>
                <div className={styles.buttonSubGroup}>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                    </div>
            </div>

            <Dialog
                open={deleteConfirmation.open}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this attachment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </form>
    );
};

export default IncidentEdit;