// Incident.tsx
import React, { MouseEvent, useState } from 'react';
import { Incident as IncidentType, IncidentData } from '../../model/model';
import styles from './Incidents.module.css';
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import IncidentEdit from '../IncidentEdit/IncidentEdit';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

interface IncidentProps {
  incident: IncidentType;
  incidentData: IncidentData | null;
  index: number;
  onClick: (incident: IncidentType) => void;
  isActive: boolean;
  onProcess: () => void;
}

const Incident: React.FC<IncidentProps> = ({ 
  incident, 
  incidentData, 
  index, 
  onClick, 
  isActive,
  onProcess
  }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const [title, setTitle] = useState('');
    let timer: NodeJS.Timeout;
    
    const handleMouseEnter = () => {
      timer = setTimeout(() => {
        setTitle(`${incident.id}${incident.comment ? `\n${incident.comment}` : ''}`);
            }, 1000);
    };
    
    const handleMouseLeave = () => {
      clearTimeout(timer);
      setTitle('');
    };    

    const handleProcessClick = async ( ignoreAccompanying: boolean = true) => {
      // try {
      //   const result = await reProcessIncident(incident.id, ignoreAccompanying);
      //   enqueueSnackbar(`Success`, { variant: 'success' });
      //   onProcess(); // Trigger a re-render
      // } catch (error) {
      //   enqueueSnackbar(`Process failed: ${(error as Error).message}`, { variant: 'error' });
      // }
    }

  // delete with all the associated data
  const handleDeleteClick = async () => {
    // confirm the deletion
    if (!window.confirm(`Do you really want to delete the incident and its data? 
    ${incident.callsign} - ${incident.hexident} - ${incident.timestamp}`)) {
      return;
    }
    // try {
    //   await deleteIncident(incident.id);
    //   enqueueSnackbar(`Incident deleted`, { variant: 'success' });
    //   onProcess(); // Trigger a re-render
    // } catch (error) {
    //   enqueueSnackbar(`Delete failed: ${(error as Error).message}`, { variant: 'error' });
    // }

  }

  // remove from incidents
  const handleRemoveClick = async () => {
    // confirm the deletion
    if (!window.confirm(`Do you really want to remove from the incidents? 
    ${incident.callsign} - ${incident.hexident} - ${incident.timestamp}
    with ID: ${incident.id}`)) {
      return;
    }
    // try {
    //   await removeIncident(incident.id);
    //   enqueueSnackbar(`Incident removed`, { variant: 'success' });
    //   onProcess(); // Trigger a re-render
    // }
    // catch (error) {
    //   enqueueSnackbar(`Remove failed: ${(error as Error).message}`, { variant: 'error' });
    // }
  }


  const handleIncidentEdit = async (incident: IncidentType) => {
    // try {
    //   console.log(incident);
    //   await updateIncident(incident);
    //   enqueueSnackbar(`Incident updated`, { variant: 'success' });
    //   onProcess(); // Trigger a re-render
    // }
    // catch (error) {
    //   enqueueSnackbar(`Update failed: ${(error as Error).message}`, { variant: 'error' });
    // }
  }

  const handleImportFR24Click = async () => {
    // try {
    //   await importFR24(incident.id);
    //   enqueueSnackbar(`Success`, { variant: 'success' });
    //   onProcess(); // Trigger a re-render
    // } catch (error) {
    //   enqueueSnackbar(`Import FR24 failed: ${(error as Error).message}`, { variant: 'error' });
    // }

  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };  

  return (
    <li className={`${styles.card} ${isActive ? styles.active : (incident.detected_by === 'SIM' ? styles.sim : '')}`} 
        onClick={() => onClick(incident)}
        onDoubleClick={() => setEditDialogOpen(true)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        title={title}       
        >        
      <div className={styles.index}> {index + 1}</div>
      <div className={styles.header}>
        <div>
          <p>{incident.regnum}</p>
          <p>{incident.callsign}</p>
        </div>
      </div>
      <div className={styles.firstPart}>
        <p>Hexident: {incident.hexident}</p>
        <p>UTC: {incident.timestamp.split(".")[0]}</p>
        <p>Altitude: {incident.altitude} ft</p>
        <p>Latitude: {incident.latitude}</p>
        <p>Longitude: {incident.longitude}</p>
      </div>
      <div className={styles.secondPart}>
        <p>Detected by: {incident.detected_by}</p>
        <p>Distance: {incident.distance} km</p>
        <p>Event type: {incident.event_type}</p>
        <p>Phase: {incident.flight_phase}</p>
        <p>Ipk: {incident.peak_current} kA (AI: {incident.AI} kA2s)</p>
      </div>
      <div className={styles.thirdPart}>
        <p>{incident.confirmed ? 'Confirmed' : ''}</p>
        <p>{incident.miss ? 'Miss' : ''}</p>
        <p>{incident.damage_score !== null ? `Damage predicted: ${incident.damage_score}` : '' }</p> 
        <p>{incident.reported_damage !== null ? `Damage reported: ${incident.reported_damage}` : '' }</p> 
        <p>{incident.AOG !== null ? `AOG: ${incident.AOG}` : '' }</p>        
        <p>{incident.nac_p ? `NACp: ${incident.nac_p}` : '' }</p>        
      </div>
      <div className={styles.fourthPart}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: 'white' }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { setEditDialogOpen(true); handleClose(); }}>Edit</MenuItem>
        <Divider />
        <MenuItem onClick={() => { handleProcessClick(); handleClose(); }}>Process this</MenuItem>
        <MenuItem onClick={() => { handleProcessClick(false); handleClose(); }}>Process all</MenuItem>
        <MenuItem onClick={() => { handleImportFR24Click(); handleClose(); }}>Import FR24 track</MenuItem>
      </Menu>   

          <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="xl">
            <DialogTitle>Edit Incident</DialogTitle>
            <DialogContent>
              <IncidentEdit incident={incident} 
                            onSubmit={(editedIncident) => {
                                  handleIncidentEdit(editedIncident);
                                  setEditDialogOpen(false);
                                }}
                            onRemove={handleRemoveClick}
                            onDelete={handleDeleteClick}
                      />
            </DialogContent>
          </Dialog>         
      </div>
    </li>
  );
};

export default Incident;