import React, { MouseEvent, useState, useCallback, useEffect } from 'react';
import { Incident as IncidentType, IncidentData, Damage } from '../../model/model';
import styles from './Incidents.module.css';
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import IncidentEdit from '../IncidentEdit/IncidentEdit';
import { updateIncident, removeIncident, importTrack, getIncidentData, getIncidentAttachments, getDamage } from '../../api/apiService';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { getIncidents } from '../../api/apiService';
import { Attachment } from '../../model/model';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Tooltip from '@material-ui/core/Tooltip';

interface IncidentProps {
  incident: IncidentType;
  incidentData: IncidentData | null;
  incidentDamageData: Damage | null;
  incidentAttachments: Attachment[];
  index: number;
  onClick: (incident: IncidentType) => void;
  isActive: boolean;
  onProcess: () => void;
  isHighlighted: boolean; // Add this new prop
}

const Incident: React.FC<IncidentProps> = ({
  incident,
  incidentData,
  incidentDamageData,
  incidentAttachments,
  index,
  onClick,
  isActive,
  onProcess,
  isHighlighted, // Add this new prop
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentIncident, setCurrentIncident] = useState(incident);
  const [currentIncidentData, setCurrentIncidentData] = useState(incidentData);
  const [currentIncidentAttachments, setCurrentIncidentAttachments] = useState<Attachment[]>(incidentAttachments);
  const [currentIncidentDamageData, setCurrentIncidentDamageData] = useState<Damage | null>(incidentDamageData ?? null);
  const [title, setTitle] = useState('');
  let timer: ReturnType<typeof setTimeout>;
  const handleMouseEnter = () => {
    timer = setTimeout(() => {
      setTitle(`${incident.id}${incident.comment ? `\n${incident.comment}` : ''}`);
    }, 1000);
  };

  const handleMouseLeave = () => {
    clearTimeout(timer);
    setTitle('');
  };

  const handleProcessClick = async (ignoreAccompanying: boolean = true) => {
    // try {
    //   const result = await reProcessIncident(incident.id, ignoreAccompanying);
    //   enqueueSnackbar(`Success`, { variant: 'success' });
    //   onProcess(); // Trigger a re-render
    // } catch (error) {
    //   enqueueSnackbar(`Process failed: ${(error as Error).message}`, { variant: 'error' });
    // }
  }

  // delete with all the associated data
  const handleDeleteClick = async () => {
    // confirm the deletion
    if (!window.confirm(`Do you really want to delete the incident and its data? 
    ${incident.callsign} - ${incident.hexident} - ${incident.timestamp}`)) {
      return;
    }
    // try {
    //   await deleteIncident(incident.id);
    //   enqueueSnackbar(`Incident deleted`, { variant: 'success' });
    //   onProcess(); // Trigger a re-render
    // } catch (error) {
    //   enqueueSnackbar(`Delete failed: ${(error as Error).message}`, { variant: 'error' });
    // }

  }

  // remove from incidents
  const handleRemoveClick = async () => {
    // confirm the deletion
    if (!window.confirm(`Are you sure you want to remove this incident from the database? This action cannot be undone. \n
    ${incident.callsign} - ${incident.hexident} - ${incident.timestamp}
    ID: ${incident.id}`)) {
      return;
    }
    try {
      await removeIncident(incident.id);
      enqueueSnackbar(`Incident removed`, { variant: 'success' });
      onProcess(); // Trigger a re-render
    }
    catch (error) {
      enqueueSnackbar(`Remove failed: ${(error as Error).message}`, { variant: 'error' });
    }
  }


  const handleIncidentEdit = async (incident: IncidentType) => {
    try {
      await updateIncident(incident);
      enqueueSnackbar(`Incident updated`, { variant: 'success' });
      onProcess(); // Trigger a re-render
    }
    catch (error) {
      enqueueSnackbar(`Update failed: ${(error as Error).message}`, { variant: 'error' });
    }
  }

  const handleImportTrackClick = () => {
    importTrack(incident.id)
      .then(() => {
        enqueueSnackbar(`Track imported successfully`, { variant: 'success' });
        onProcess(); // Trigger a re-render
      })
      .catch((error) => {
        enqueueSnackbar(`Import track failed: ${error.message}`, { variant: 'error' });
      })
      .finally(() => {
        handleClose(); // Close the menu after the operation (success or failure)
      });
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleAttachmentUploaded = useCallback(async (newAttachment: Attachment) => {
    setCurrentIncidentAttachments([...currentIncidentAttachments, newAttachment]);
  }, [currentIncidentAttachments]);

  const handleAttachmentDeleted = useCallback(async () => {
    setEditDialogOpen(false);

    try {
      // Re-fetch the updated incident and incidentData and incidentAttachments
      const updatedIncident = (await getIncidents(0, 1, { id: incident.id }))[0];
      const updatedIncidentData = await getIncidentData(incident.id);
      const updatedIncidentAttachments = await getIncidentAttachments(incident.id);
      const updatedIncidentDamageData = await getDamage(incident.id);

      setCurrentIncident(updatedIncident);
      setCurrentIncidentData(updatedIncidentData);
      setCurrentIncidentAttachments(updatedIncidentAttachments);
      setCurrentIncidentDamageData(updatedIncidentDamageData);
      setEditDialogOpen(true);
      onProcess(); // Trigger a re-render of the parent component
    } catch (error) {
      enqueueSnackbar(`Failed to refresh incident data: ${(error as Error).message}`, { variant: 'error' });
    }
  }, [incident.id, enqueueSnackbar, onProcess]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLatestData = async () => {
      if (editDialogOpen && !isLoading) {
        setIsLoading(true);
        try {
          const [updatedIncident] = await getIncidents(0, 1, { id: incident.id });
          const updatedIncidentData = await getIncidentData(incident.id);
          const updatedIncidentAttachments = await getIncidentAttachments(incident.id);
          const updatedIncidentDamageData = await getDamage(incident.id);

          setCurrentIncident(updatedIncident);
          setCurrentIncidentData(updatedIncidentData);
          setCurrentIncidentAttachments(updatedIncidentAttachments);
          setCurrentIncidentDamageData(updatedIncidentDamageData);
        } catch (error) {
          enqueueSnackbar(`Failed to fetch latest incident data: ${(error as Error).message}`, { variant: 'error' });
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchLatestData();
  }, [editDialogOpen, incident.id, enqueueSnackbar]);

  return (
    <li className={`${styles.card} 
                    ${styles.noSelect}
                    ${isActive ? styles.active :
        incident.detected_by === 'SIM' ? styles.sim :
          isHighlighted ? styles.highlighted : ''}`}
      onClick={() => onClick(incident)}
      onDoubleClick={() => setEditDialogOpen(true)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title={title}
    >
      <div className={styles.index}> {index + 1}</div>
      <div className={styles.header}>
        <div>
          <div>{incident.regnum}</div>
          <div>{incident.callsign}</div>
        </div>
      </div>
      <div className={styles.firstPart}>
        <div>Hexident: {incident.hexident}</div>
        <div>{incident.timestamp.split(".")[0]}</div>
        <div>Altitude: {incident.altitude} ft</div>
        <div>Latitude: {incident.latitude}</div>
        <div>Longitude: {incident.longitude}</div>
      </div>
      <div className={styles.secondPart}>
        <div>Detected by: {incident.detected_by}</div>
        <div>Distance: {incident.distance} km</div>
        <div>Ipk: {incident.peak_current} kA (AI: {incident.AI} kA2s)</div>
        <div>Phase: {incident.flight_phase}</div>
      </div>
      <div className={styles.thirdPart}>
        <div>{incident.event_type ? `Type: ${incident.event_type}` : ''}</div>
        <div>{incident.damage_score !== null ? `Damage predicted: ${incident.damage_score}` : ''}</div>
        <div>{incident.reported_damage !== null ? `Damage reported: ${incident.reported_damage}` : ''}</div>
        <div>{incident.AOG !== null ? `AOG: ${incident.AOG}` : ''}</div>
        <div>{incident.nac_p ? `NACp: ${incident.nac_p}` : ''}</div>
      </div>
      <div className={styles.fourthPart}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ color: 'white' }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => { setEditDialogOpen(true); handleClose(); }}>Edit</MenuItem>
          <Divider />
          {/* <MenuItem onClick={() => { handleProcessClick(); handleClose(); }}>Process this</MenuItem>
        <MenuItem onClick={() => { handleProcessClick(false); handleClose(); }}>Process all</MenuItem> */}
          <MenuItem onClick={() => { handleImportTrackClick(); handleClose(); }}>Import track</MenuItem>
        </Menu>

        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="xl">
          <DialogTitle>Edit Incident</DialogTitle>
          <DialogContent>
            {isLoading ? (
              <div>Loading latest data...</div>
            ) : (
              <IncidentEdit
                key={`${currentIncident.id}-${editDialogOpen}`}
                incident={currentIncident}
                incidentData={currentIncidentData as IncidentData}
                incidentAttachments={currentIncidentAttachments}
                incidentDamageData={currentIncidentDamageData as Damage}
                onSubmit={(editedIncident) => {
                  handleIncidentEdit(editedIncident);
                  setEditDialogOpen(false);
                }}
                onRemoveIncident={handleRemoveClick}
                onDeleteIncident={handleDeleteClick}
                onCancel={() => setEditDialogOpen(false)}
                onAttachmentDeleted={handleAttachmentDeleted}
                onAttachmentUploaded={handleAttachmentUploaded}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
      {Number(currentIncident.attachment_count) > 0 && (
        <Tooltip title={`${currentIncident.attachment_count} attachment(s)`} arrow>
          <AttachFileIcon className={styles.attachmentIcon} />
        </Tooltip>
      )}
    </li>
  );
};

export default Incident;