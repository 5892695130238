import React, { useState } from 'react';
import './SettingsPopup.css';

interface SettingsPopupProps {
  onClose: () => void;
  onSave: (lightningHistoryDays: number) => void;
  initialLightningHistoryDays: number;
}

const SettingsPopup: React.FC<SettingsPopupProps> = ({ onClose, onSave, initialLightningHistoryDays }) => {
  const [lightningHistoryDays, setLightningHistoryDays] = useState(initialLightningHistoryDays);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(lightningHistoryDays);
  };

  return (
    <div className="settings-popup">
      <div className="settings-popup-content">
        <h2>Settings</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Lightning history (days):
            <input
              type="number"
              value={lightningHistoryDays}
              onChange={(e) => setLightningHistoryDays(Number(e.target.value))}
              min="1"
              max="30"
            />
          </label>
          <div className="button-group">
            <button type="submit">Save</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SettingsPopup;