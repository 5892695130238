// FilterPanel.tsx
import React, { useState } from 'react';
import styles from './FilterPanel.module.css'; // Import styles


interface FilterPanelProps {
    onFilterChange: (filters: {regnum?: string, callsign?: string, hexident?: string }) => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({ onFilterChange }) => {
    const [regnum, setRegnum] = useState('');
    const [callsign, setCallsign] = useState('');
    const [hexident, setHexident] = useState('');

    const handleFilterChange = () => {
        onFilterChange({regnum, callsign, hexident});
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          handleFilterChange();
        }
    };

    return (
        <div className={styles.filterPanel}>
            <input className={styles.filterInput} type="text" value={regnum} onChange={e => setRegnum(e.target.value)} onKeyDown={handleKeyDown} placeholder="Regnum" />
            <input className={styles.filterInput} type="text" value={callsign} onChange={e => setCallsign(e.target.value)} onKeyDown={handleKeyDown} placeholder="Callsign" />
            <input className={styles.filterInput} type="text" value={hexident} onChange={e => setHexident(e.target.value)} onKeyDown={handleKeyDown} placeholder="Hexident" />
        </div>
    );
};

export default FilterPanel;