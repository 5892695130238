// LeafletMapComponent.tsx
import React , {useEffect} from 'react';
import { MapContainer, TileLayer, useMap, ScaleControl, Marker, Tooltip } from 'react-leaflet';
import { Incident as IncidentType, IncidentData } from '../../model/model';
import { drawIncidentPath, drawIncidentPosition } from '../FlightMarker';
import { LightningMarkerForIncident, BackgroundLightningMarker } from '../LightningMarker';
import { LayerGroup, LayersControl, WMSTileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-ruler';

const { Overlay } = LayersControl;

interface MapComponentProps {
  incidents: IncidentType[];
  activeIncident: IncidentType | null;
  activeIncidentData: IncidentData | null;
  onIncidentClick: (incident: IncidentType) => void;
}


const MapUpdater: React.FC<{ center: [number, number] }> = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(center);
    }, [center, map]);
    return null;
  };  

const constructThreddsUrl = (timeStamp: string) => {
    const threddsBaseUrl = 'https://thredds.met.no/thredds/wms/remotesensing/reflectivity-nordic/';
    const year = timeStamp.slice(0, 4);
    const month = timeStamp.slice(5, 7);
    const day = timeStamp.slice(8, 10);
    let date = new Date(timeStamp+"Z");
    let seconds = date.getSeconds();
    let minutes = date.getMinutes();
    let hours = date.getHours();
    let roundedMinutes = Math.round( (minutes*60 + seconds) / 300) * 5;
    date.setHours(hours);
    date.setMinutes(roundedMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    let time = date.toISOString();
    console.log(threddsBaseUrl+year+"/"+month+"/yrwms-nordic.mos.pcappi-0-dbz.noclass-clfilter-novpr-clcorr-block.nordiclcc-1000."+year+month+day+".nc?time="+time);
    return threddsBaseUrl+year+"/"+month+"/yrwms-nordic.mos.pcappi-0-dbz.noclass-clfilter-novpr-clcorr-block.nordiclcc-1000."+year+month+day+".nc?time="+time;
}

declare module 'leaflet' {
    namespace control {
      function ruler(options?: any): Control;
    }
  }

const AddRulerControl = () => {
    const map = useMap();

    useEffect(() => {
        L.control.ruler().addTo(map);
    }, [map]);

    return null;
    };


// Define the createLayers function
const createLightningLayers = (sources: string[], activeIncidentData: IncidentData, activeIncident: IncidentType | null) => {

    return sources.map(source => (
      <Overlay checked name={source} key={source}>
        <LayerGroup>

          {activeIncident && activeIncidentData && activeIncidentData.background_sferics?.filter(item => item.source === source).map((sferic, i) => (
              <BackgroundLightningMarker
              key={i}
              index={i}
              timeZero={
                activeIncidentData.incident_sferics.filter(item => item.source === source).length > 0
                  ? new Date(
                      activeIncidentData.incident_sferics
                        .filter(item => item.source === source)
                        .reduce((acc, curr) => (acc.timestamp || Infinity) < curr.timestamp ? acc : curr)
                        .timestamp
                    ).toISOString()
                  : activeIncident.timestamp // default value
              }
              {...sferic}
              />))}

          {activeIncidentData &&
          activeIncident &&
          activeIncidentData.incident_sferics?.filter(item => item.source === source).map((sferic, i) => (
              <LightningMarkerForIncident
              key={i}
              index={i}
              timeZero={
                activeIncidentData.incident_sferics.filter(item => item.source === source).length > 0
                  ? new Date(
                      activeIncidentData.incident_sferics
                        .filter(item => item.source === source)
                        .reduce((acc, curr) => (acc.timestamp || Infinity) < curr.timestamp ? acc : curr)
                        .timestamp
                    ).toISOString()
                  : activeIncident.timestamp // default value
              }
              {...sferic}
              />))}
        </LayerGroup>
      </Overlay>
    ));
  };    

  const colors = ['#628395', '#724395', 'green', 'purple', 'orange']; // Add more colors as needed
  const createTrackLayers = (activeIncidentData: IncidentData | null, activeIncident: IncidentType | null) => {
    return activeIncidentData?.tracks.map((track, i) => {
      const color = colors[i % colors.length]; // Select a color based on the index
      return (
        <Overlay checked name={`Track ${track[0]['callsign']}`} key={i}>
          <LayerGroup>
            {drawIncidentPath(track, color)}
            {activeIncidentData.incident_ac_positions?.map((flight) => (drawIncidentPosition(flight, track)))}
          </LayerGroup>
        </Overlay>
      );
    });
  };

const MapComponent: React.FC<MapComponentProps> = ({ incidents, activeIncident, activeIncidentData, onIncidentClick }) => {
  const position: [number, number] = activeIncident ? [activeIncident.latitude, activeIncident.longitude - .15] : [60., 6.];

  // Find all unique sources
  const sources = Array.from(new Set([
    ...(activeIncidentData?.incident_sferics?.map(sferic => sferic.source) || []),
    ...(activeIncidentData?.background_sferics?.map(sferic => sferic.source) || [])
  ]));

// Create layers for each lightning source
const lightning_layers = activeIncidentData && createLightningLayers(sources, activeIncidentData, activeIncident);
// Create layers for each track
const AircraftLayers = createTrackLayers(activeIncidentData, activeIncident);

  return (

    <MapContainer 
        center={position}  
        zoom={activeIncident?12 : 6} 
        style={{ height: "100%", width: "100%" }}
        zoomControl={false} >

    {/* <MapUpdater center={position} /> */}

      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

<LayersControl position="topright">

    <Overlay name="Radar">
    {activeIncident && (<WMSTileLayer
          attribution='<a href="https://www.met.no/en">MET Norway</a>'
          version="1.3.0"
          layers="equivalent_reflectivity_factor"
          opacity={0.5}
          format="image/png"
          transparent={true}        
          url={constructThreddsUrl(activeIncident.timestamp)}
        />
    )}
    </Overlay>

    {activeIncidentData && AircraftLayers}

    {activeIncidentData && lightning_layers}


</LayersControl>

  {!activeIncident && incidents.map((incident) => (
          <Marker 
            position={[incident.latitude, incident.longitude]} 
            key={incident.id}
            eventHandlers={{ click: () => onIncidentClick(incident) }}
            >
            <Tooltip>
              <p><b>{incident.regnum? incident.regnum : ""}</b> {incident.callsign? incident.callsign : ""}</p>
              <p>{incident.timestamp? incident.timestamp : ""}</p>
            </Tooltip>
          </Marker>
        ))}

    <ScaleControl position="bottomright" />

    <AddRulerControl />
    
    </MapContainer>
  );
};

export default MapComponent;