import { Control, ControlOptions } from "leaflet";
import { useMap } from "react-leaflet";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import incidentIcon from '../../assets/icons8-lightning-48.png';
import './IncidentControl.css';

interface IncidentControlProps extends ControlOptions {
  incidentCount: number;
}

const IncidentControl = ({ incidentCount, ...props }: IncidentControlProps) => {
  const map = useMap();
  const navigate = useNavigate();

  useEffect(() => {
    const IncidentControl = Control.extend({
      onAdd: () => {
        const container = document.createElement('div');
        container.className = "incident-control";
        container.title = "Incidents";
        container.innerHTML = `
          <img src="${incidentIcon}" alt="Report Incident" width="38" height="38" />
          <span class="incident-badge">${incidentCount}</span>
        `;

        container.onclick = () => {
          navigate('/incidents');
        };

        return container;
      }
    });

    const controlOptions: ControlOptions = {
      position: 'bottomleft',
      ...props
    };

    const control = new IncidentControl(controlOptions);
    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map, props, navigate, incidentCount]);

  return null;
};

export default IncidentControl;
