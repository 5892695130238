import { Marker, Tooltip, useMapEvents, LayerGroup, Popup } from "react-leaflet";
import { Lightning } from "../model/model";
import { getMinutesSince } from "../utils/DateUtil";
import { getLightningDataOverview, getLightningDataOverviewForIncident, getLightningOpacity } from "../utils/LightningUtil";
import { lightningIconIC, lightningIconCG } from "./Icons";
import { useMap } from 'react-leaflet';
import 'leaflet-ellipse';
import L from 'leaflet';
import React from 'react';
import { useEffect } from 'react';
import { DivIcon } from 'leaflet';
import { light } from "@material-ui/core/styles/createPalette";


const LightningMarker = (lightning: Lightning, setEstimatedPosition?: any) => {
  const minutesSinceLightning = getMinutesSince(lightning.timestamp);
  return (
    <Marker
      key={lightning.id}
      position={[lightning.latitude, lightning.longitude]}
      icon={lightning.cloud_indicator ? lightningIconIC : lightningIconCG}
      opacity={getLightningOpacity(minutesSinceLightning)}
      keyboard={false}
    >
      <Tooltip sticky className="lightning-tooltip">
        {getLightningDataOverview(lightning, minutesSinceLightning)}
      </Tooltip>
    </Marker>
  );
}

type LightningMarkerForIncidentProps = Lightning & {
  index: number;
  timeZero: string;
};

interface EllipseProps {
  position: [number, number];
  semiMajorAxes: number;
  semiMinorAxes: number;
  angle: number;
}

const Ellipse: React.FC<EllipseProps> = ({ position, semiMajorAxes, semiMinorAxes, angle }) => {
  const map = useMap();

  useEffect(() => {
    const ellipse = L.ellipse(
      position, 
      [semiMajorAxes*1e3, semiMinorAxes*1e3], 
      angle-90, 
      {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.3,
        startAngle: 0,
        endAngle: 359,
        fill: true,
        weight: .5,
        interactive: false,
      }
    ).addTo(map);

    return () => {
      map.removeLayer(ellipse);
    };
  }, [map, position, semiMajorAxes, semiMinorAxes, angle]);

  return null;
};

const LightningMarkerForIncident: React.FC<LightningMarkerForIncidentProps> = ({ index, timeZero, ...lightning }) => {
  const position: [number, number] = [lightning.latitude, lightning.longitude];
  const secondsSince = Math.floor(new Date(lightning.timestamp).getTime() - new Date(timeZero).getTime()) / 1000;
  const indexIcon = new DivIcon({
    className: 'index-icon',
    html: `<b>${index+1}</b>`,
    iconSize: [10, 10],
    iconAnchor: [-5, 20],
  });  
  return (
    <LayerGroup>
    <Marker
      key={lightning.id}
      position={[lightning.latitude, lightning.longitude]}
      icon={indexIcon}
      keyboard={false}>
      <Tooltip sticky className="lightning-tooltip">
        {getLightningDataOverviewForIncident(lightning, secondsSince)}
      </Tooltip>
    </Marker>
    <Ellipse 
        position={position} 
        semiMajorAxes={lightning.semi_major_axes} 
        semiMinorAxes={lightning.semi_minor_axes} 
        angle={lightning.angle} 
      />
    </LayerGroup>
  );
}

const BackgroundLightningMarker: React.FC<LightningMarkerForIncidentProps> = ({ timeZero, ...lightning }) => {
  const secondsSince = Math.floor(new Date(lightning.timestamp).getTime() - new Date(timeZero).getTime()) / 1000;
  return (
    <Marker
      key={lightning.id}
      position={[lightning.latitude, lightning.longitude]}
      icon={lightning.cloud_indicator ? lightningIconIC : lightningIconCG}
      opacity={getLightningOpacity(secondsSince)}
      keyboard={false}
    >
      <Tooltip sticky className="lightning-tooltip">
        {getLightningDataOverviewForIncident(lightning, secondsSince)}
      </Tooltip>
      <Popup>
        <div>
          <p>Coords: {lightning.latitude}, {lightning.longitude}</p>
          <p>Timestamp: {new Date(lightning.timestamp).toISOString()}</p>
          <p>Cloud indicator: {lightning.cloud_indicator ? 'IC' : 'CG'}</p>
          <p>Peak current: {lightning.peak_current} kA</p>
          <p>Rise time: {lightning.rise_time} mus</p>
          <p>Angle: {lightning.angle}°</p>
          <p>Semi major axes: {lightning.semi_major_axes} km</p>
          <p>Semi minor axes: {lightning.semi_minor_axes} km</p>
        </div>
      </Popup>

    </Marker>
  );
}

export { LightningMarker, LightningMarkerForIncident, BackgroundLightningMarker };
