import { Flight, Lightning, Incident, IncidentData, Airplane } from "../model/model";
import { deleteOperation, get, post } from "./http";
import Cookies from 'js-cookie';

//const baseUrl = "https://app-lightning-api-dev.azurewebsites.net/api";
const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8080/api";
const FlightsLimit = process.env.REACT_APP_FLIGHTS_LIMIT || 2000;
const SfericsLimit = process.env.REACT_APP_SFERICS_LIMIT || 1000;

const getAuthHeaders = (): HeadersInit => {
  const token = localStorage.getItem('token');
  return {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

async function getFlights(): Promise<Flight[]> {
  try {
    const response = await fetch(`${baseUrl}/Flights/db/?limit=${FlightsLimit}`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching flights:", error);
    return [];
  }
};

const getFlightHistory = async (callSign: string): Promise<Flight[] | undefined> => {
  try {
    const response = await fetch(`${baseUrl}/Flights/callsign/${callSign}`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching flight history:", error);
    return undefined;
  }
};

const getMinimalLightnings = async (
  skip: number = 0
): Promise<Lightning[]> => {
  let url = `${baseUrl}/Lightnings/minimal?limit=${SfericsLimit}&skip=${skip}`;
  
  const LightningHistoryDays = Cookies.get('lightningHistoryDays') || 2;
  const fromDate = new Date(new Date().getTime() - Number(LightningHistoryDays) * 24 * 60 * 60 * 1000);

  // Round up to the nearest minute
  fromDate.setSeconds(0, 0);
  fromDate.setMinutes(fromDate.getMinutes() + 1);

  url += `&from_date=${fromDate.toISOString()}`;

  try {
    const response = await fetch(url, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching minimal lightnings:", error);
    return [];
  }
};

export interface Filters {
  regnum?: string;
  callsign?: string;
  hexident?: string;
  [key: string]: string | undefined;
}

const getIncidents = async (skip: number, limit: number, filters: Filters): Promise<Incident[]> => {
  const queryString = Object.keys(filters)
    .filter(key => filters[key] !== undefined && filters[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key] || '')}`)
    .join('&');  
  try {
    const response = await fetch(`${baseUrl}/Incident?skip=${skip}&limit=${limit}&${queryString}`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching incidents:", error);
    return [];
  }
}

const submitAcRegistration = async (acInfo: Airplane): Promise<void> => {
  const response = await fetch(`${baseUrl}/Aircraft/add-registration`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(acInfo),
  });
  if (!response || !response.ok) {
    throw new Error(`Failed to submit aircraft registration`);
  }
}

export interface SignInResponse {
  access_token: string;
  // Add any other properties that the response might include
}

const signIn = async (username: string, password: string): Promise<SignInResponse> => {
  // Update this URL to match your backend route
  const response = await fetch(`${baseUrl}/users/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      username: username,
      password: password,
    })
  });

  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'An error occurred during sign in');
    }
    return response.json();
  } else {
    console.error('Non-JSON response:', await response.text());
    throw new Error('Received non-JSON response from server');
  }
};

export {

  // flights
  getFlights,
  getFlightHistory,

  // lightnings
  getMinimalLightnings,

  // incidents
  getIncidents,
  // fleet
  submitAcRegistration,

  // other
  signIn,
};

export const getUserInfo = async (): Promise<{ username: string; email: string }> => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  const response = await fetch(`${baseUrl}/users/me`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user info');
  }

  return response.json();
};
